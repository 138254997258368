import { Navigate, useLocation, useNavigate } from "react-router-dom";

import { useEffect } from "react";

const LocationHandler = ({ children }: { children: any }) => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    console.log(
      `Location changed to: ${window.location.origin}/#${location.pathname}${location.search}`
    );
    if (window.parent.frames.length > 0) {
      console.log("We are an iframe");
      window.parent.postMessage(
        {
          type: "setLocation",
          message: `/#${location.pathname}${location.search}`,
        },
        "*"
      );
    } else {
      console.log("we are not an iframe");
    }
  }, [location]);

  window.addEventListener("message", (ev: MessageEvent) => {
    if (ev.data.type === "setLocationFromParent") {
      navigate(`/${ev.data.message}`);
    }
  });

  return <>{children}</>;
};

export default LocationHandler;
