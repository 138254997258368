import { useParams } from "react-router-dom";


const JobDetail = () => {
  let { jobId } = useParams();
  // we redirect now
  window.parent.postMessage(
    {
      type: "redirect",
      message: `https://app.studyheads.de/display/page/jobangebote/job/${jobId}`,
    },
    "*"
  );
  return (
    <>
    </>
  );
};

export default JobDetail;
