import "./App.css";

import {
  AllOptions,
  Interessent,
  InteressentContext,
  InteressentObject,
  IPContext,
  Jobangebot,
  JobangeboteContext,
  LoadedContext,
  Niederlassung,
  NiederlassungContext,
  NlnrContext,
  OptionsContext,
} from "./store/context";
import { HashRouter, Route, Routes, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import useAsyncEffect from 'use-async-effect';
import axios from "axios";
import { getCookie, setCookie } from "./store/cookies";

import Card from "./components/Card";
import JobDetail from "./screens/JobDetail";
import JobDetailWithoutData from "./screens/JobDetailWithoutData";
import LocationHandler from "./components/LocationHandler";
import NiederlassungenList from "./components/NiederlassungenList";

//@ts-ignore
import localization from "moment/locale/de";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { apiBase, fetchInteressent, fetchIP, fetchJobangebote, fetchNiederlassungen, fetchOptions } from "./store/api";



function App() {
  return (
    <HashRouter>
      <LocationHandler>
          <Routes>
            <Route path="/job/:jobId" element={<JobDetail />} />
          </Routes>
      </LocationHandler>
    </HashRouter>
  );
}

export default App;
